import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationSixthInfo = () => (
  <section className="location-info location-info--reverse">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 location-info__content">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="content__title mb-50">
              Duży wybór <br />
              przedszkoli i szkół
            </h2>
            <p className="content__text mb-50">
              Budynek stanowi także atrakcyjną lokalizację dla rodzin z dziećmi.
              W dzielnicy Krowodrza znajduje się aż 16 publicznych i 21
              niepublicznych przedszkoli, a także szkoły podstawowe i licea.
              Okolica oferuje place zabaw i zielone tereny, z których można
              skorzystać w ramach aktywnego spędzania czasu.
            </p>
          </Fade>
        </div>
        <div className="col-12 col-lg-5 offset-lg-1 location-info__image location-info__image--full">
          <Fade delay={300} direction={"right"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info6.jpg")}
              alt=""
            />
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationSixthInfo
