import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationSecondInfo = () => (
  <section className="location-info location-info--reverse location-info--second">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 location-info__content">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="content__title">Zielona okolica</h2>
            <p className="content__subtitle subtitle mb-40">
              Aktywny wypoczynek po pracy
            </p>
            <p className="content__text mb-50">
              Przyjemne skwerki znajdujące się blisko obiektu oferują liczne
              możliwości spędzania czasu wolnego. Piknik w parku Jordana, czy
              jogging wokół błoni to tylko jedne z możliwości oferowanych przez
              okolicę KW51. Najbliższy plac zabaw i siłownia na świeżym
              powietrzu znajdują się w odległości kilkudziesięciu metrów, tuż
              obok Młynówki Królewskiej.
            </p>
            <div className="content__info content__info--column mb-50">
              <p className="h3 font-regular info__text info__text--mobile-justyfy mb-15">
                Młynówka Królewska{" "}
                <span>4,5 km zielonych tras dla pieszych i rowerów</span>
              </p>
              <p className="h3 font-regular info__text info__text--mobile-justyfy mb-15">
                Park im. Jordana i błonia krakowskie{" "}
                <span>1,5 km od inwestycji</span>
              </p>
              <p className="h3 font-regular info__text info__text--mobile-justyfy mb-15">
                Park im. Świętego Wincentego á Paulo{" "}
                <span>1 km od inwestycji</span>
              </p>
              <p className="h3 font-regular info__text mb-15">
                Park Krakowski <span>900 m od inwestycji</span>
              </p>
              <p className="h3 font-regular info__text">
                Park Kleparski <span>1,8 km od inwestycji</span>
              </p>
            </div>
          </Fade>
        </div>
        <div className="col-12 col-lg-5 offset-lg-1 location-info__image">
          <Fade delay={300} direction={"right"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info2.jpg")}
              alt=""
            />
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationSecondInfo
