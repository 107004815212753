import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationFourthInfo = () => (
  <section className="location-info location-info--reverse location-info--fourth">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 location-info__content">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="content__title mb-50">
              Spacerem<br />
              na Stare Miasto
            </h2>
            <p className="content__text content__text--mobile-center mb-50">
              Budynek znajduje się jedynie 2 km do Rynku.
            </p>
            <div className="content__info content__info--mobile-centered">
              <p className="h3 font-regular info__text">3 min autem</p>
              <p className="h3 font-regular info__text">15 minut pieszo</p>
            </div>
          </Fade>
        </div>
        <div className="col-12 col-lg-5 offset-lg-1 location-info__image">
          <Fade delay={300} direction={"right"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info4.jpg")}
              alt=""
            />
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationFourthInfo
