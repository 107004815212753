import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import LocationText from "../components/location/locationText"
import LocationMapHolder from "../components/locationMapHolder"
import LocationFirstInfo from "../components/location/locationFirstInfo"
import LocationSecondInfo from "../components/location/locationSecondInfo"
import LocationThirdInfo from "../components/location/locationThirdInfo"
import LocationFourthInfo from "../components/location/locationFourthInfo"
import LocationFifthInfo from "../components/location/locationFifthInfo"
import LocationSixthInfo from "../components/location/locationSixthInfo"
import LocationSeventhInfo from "../components/location/locationSeventhInfo"
import LocationEighthInfo from "../components/location/locationEighthInfo"

const Location = () => (
	<Layout>
		<SEO title="Lokalizacja" />
		<Banner title="Lokalizacja" />
        <LocationText />
        <LocationMapHolder plainMap={ true } />
        <LocationFirstInfo />
		<LocationSecondInfo />
		<LocationThirdInfo />
		<LocationFourthInfo />
		<LocationFifthInfo />
		<LocationSixthInfo />
		<LocationSeventhInfo />
		<LocationEighthInfo />
	</Layout>
)

export default Location
