import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationFirstInfo = () => (
  <section className="location-info location-info--first">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-5 location-info__image">
          <Fade delay={150} direction={"left"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info1.jpg")}
              alt=""
            />
          </Fade>
        </div>
        <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 location-info__content">
          <Fade delay={300} direction={"right"} triggerOnce>
            <h2 className="content__title mb-50">
              Wygodne połączenie komunikacji miejskiej
            </h2>
            <p className="content__text mb-50">
              Oaza spokoju w tętniącej życiem okolicy. Nieruchomość
              zlokalizowana jest przy skrzyżowaniu ul. Kazimierza Wielkiego i
              ul. Urzędniczej, na której znajduje się doskonale skomunikowany
              przystanek tramwajowy i autobusowy. Najbliższy przystanek
              tramwajowy ,,Urzędnicza’’ znajduje się zaledwie 230 m (2 minuty
              pieszo) od budynku! Kursuje z niego 5 linii tramwajowych we
              wszystkie zakątki Krakowa
            </p>
            <div className="content__info mb-50">
              <p className="h3 font-regular info__text">230 m do przystanku</p>
              <p className="h3 font-regular info__text">5 linii tramwajowych</p>
            </div>
            <p className="content__text">
              W odległości 1 przystanka tramwajowego znajduje się doskonale
              skomunikowany z całym miastem, a także strefami aglomeracyjnymi
              Plac Inwalidów. To jeden z najbardziej znanych punktów komunikacji
              miejskiej w Krakowie, z którego kursuje 5 linii tramwajowych oraz
              8 autobusowych.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationFirstInfo
