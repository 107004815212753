import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationEighthInfo = () => (
  <section className="location-info location-info--reverse location-info--eighth">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 location-info__content">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="content__title mb-50">
              Wygodne <br />
              codzienne zakupy
            </h2>
            <p className="content__text mb-50">
              W bliskiej odległości od budynku KW51 dostępne są liczne sklepy
              spożywcze (m.in. Żabka, Lewiatan), dzięki którym codzienne,
              podstawowe zakupy zrobisz po drodze do domu. 400 m od budynku
              znajduje się także drogeria Rossmann oraz apteka ZIKO (300 m).
            </p>
            <div className="content__info content__info--column">
              <p className="h3 font-regular info__text mb-15">
                Plac Targowy Nowy Kleparz <span>20 min pieszo</span>
              </p>
            </div>
          </Fade>
        </div>
        <div className="col-12 col-lg-5 offset-lg-1 location-info__image location-info__image--full">
          <Fade delay={300} direction={"right"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info8.jpg")}
              alt=""
            />
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationEighthInfo
