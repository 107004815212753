import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationFifthInfo = () => (
  <section className="location-info location-info--fifth">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-5 location-info__image location-info__image--full">
          <Fade delay={150} direction={"left"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info5.jpg")}
              alt=""
            />
          </Fade>
        </div>
        <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 location-info__content">
          <Fade delay={300} direction={"right"} triggerOnce>
            <h2 className="content__title mb-50">
              Miejsca pracy <br />i uczelnie tuż o krok
            </h2>
            <p className="content__text">
              Nieruchomość zlokalizowana jest w pobliżu ważnych punktów na mapie
              Krakowa. To idealne miejsce dla młodych, aktywnych osób, które
              poszukują lokalizacji blisko centrum miasta z dobrym dojazdem do
              pracy. Budynek mieszkalny KW51 znajduje się w bliskiej odległości
              od biurowców przy ul. Armii Krajowej, wydziałów szkół wyższych
              oraz Akademii Górniczo-Hutniczej. Uniwersytet Jagielloński jest
              oddalony zaledwie o 1,9 km.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationFifthInfo
