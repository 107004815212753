import React from "react"
import { Fade } from "react-awesome-reveal"

const LocationSeventhInfo = () => (
  <section className="location-info">
    <div className="container">
      <div className="row location-info__holder">
        <div className="col-12 col-lg-5 location-info__image location-info__image--full">
          <Fade delay={150} direction={"left"} triggerOnce>
            <img
              className="image-shadow lazyload"
              data-src={require("../../assets/img/location-info7.jpg")}
              alt=""
            />
          </Fade>
        </div>
        <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 location-info__content">
          <Fade delay={300} direction={"right"} triggerOnce>
            <h2 className="content__title mb-50">
              Dobry dojazd <br />
              do galerii handlowych
            </h2>
            <p className="content__text">
              Jedynie 2,2 km dzieli budynek od zlokalizowanej w centrum miasta
              Galerii Krakowskiej. Z łatwością można dostać się do niej rowerem,
              pieszo lub komunikacją miejską (5 przystanków tramwajowych). KW51
              jest dobrze skomunikowany także z Galerią Bronowice i sklepem IKEA
              (4 km) położonymi w sąsiedniej dzielnicy Azory.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default LocationSeventhInfo
