import React from "react"
import { Fade } from "react-awesome-reveal"

const Location = () => (
  <section className="location-text">
    <div className="container">
      <Fade delay={150} direction={"top"} triggerOnce>
        <div className="row">
          <div className="col-12">
            <p className="location-text__text">
              Jedyny w swoim rodzaju budynek mieszkalny w samym sercu Krakowa!
              Doskonała lokalizacja dla <br />
              ceniących wygodę i balans pomiędzy aktywnym lifestylem a
              wyciszeniem. Obiekt zlokalizowany jest na <br />
              <span>skrzyżowaniu ulic Kazimierza Wielkiego i Urzędniczej.</span>
            </p>
          </div>
        </div>
      </Fade>
    </div>
  </section>
)

export default Location
